select.work-order-status {
  max-width: 180px;
  &:hover {
    cursor: pointer;
  }
}

.location-container{
  &.input-field {
      width: auto;
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: 0;
      margin-right: 10px;
      .select-location{
        max-width: auto;
        min-width: 200px;
      }
  }

  label{
    margin-bottom: 0 !important;
  }
} 
