.settings {
  .tab-wrapper {
    background-color: none;
    .input-field {
      max-width: 400px;
      input,
      select {
        width: 100%;
      }
      // input:not[type="checkbox"],
      // select {
      //   width: 100%;
      // }
    }
    .pretreatment-container {
      .carriage-speed-container {
        max-width: 600px;
        .carriage-speed {
          display: grid;
          grid-template-columns: 2fr 3fr 1fr;
          column-gap: 1em;
        }
      }
    }
    .use-item-options-container {
      .item-option-keys-container {
        max-width: 600px;
        .item-option-keys {
          display: grid;
          grid-template-columns: 2fr 1fr;
          column-gap: 1em;
        }
      }
    }
    .use-order-status-container {
      .order-status-keys-container {
        max-width: 600px;
        .order-status-keys {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr;
          column-gap: 1em;
        }
      }
    }
    .platens-container {
      padding: 10px;
      .platen-wrapper {
        display: grid;
        grid-template-columns: 100px 2fr 100px 100px 1fr 1fr 1fr;
        column-gap: 1em;
      }
    }
    .packingslip-container {
      padding: 10px;
    }

    .custom-platens-container {
      max-width: 700px;
      .custom-platen-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 1em;
      }
    }

    .media-container {
      padding: 10px;
      max-width: 790px;
      .media-wrapper {
        display: grid;
        grid-template-columns: 100px 200px 100px 100px 100px 50px 35px;
        column-gap: 1em;
      }
    }

    .queues-container {
      padding: 10px;
      max-width: 925px;
      .queue-wrapper {
        display: grid;
        grid-template-columns: 200px 200px 200px 200px 50px;
        column-gap: 1em;
      }
    }

    .print-modes-container {
      padding: 10px;
      max-width: 400px;

      .print-modes-wrapper {
        display: grid;
        grid-template-columns: 315px 50px;
        column-gap: 1em;
      }
    }

    .service-codes-container {
      padding: 10px;
      max-width: 500px;
      margin-bottom: 10px;
      .service-codes-wrapper-container{
        margin-top: 10px;
        width: 100vw;
        display: flex;
        flex-wrap: wrap;
        .service-codes-wrapper {
          min-width: 500px;
          display: grid;
          grid-template-columns: 300px 50px 20px;
          column-gap: 1em;
          margin-right: 20px;
          // border: thin solid #ccc;
        }
      }
    }

    .locations-container {
      margin-bottom: 20px;
      .location-wrapper{
        display: grid;
        grid-template-columns: 200px 200px 200px 200px 50px;
        column-gap: 1em;
      }
    }
  }
}
