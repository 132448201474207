.input-field {
  margin-bottom: 20px;
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 600;
  }
}

input:not([type="checkbox"]):not([type="radio"]),
select {
  padding: 5px;
  border: thin solid $brand_text;
  border-radius: 3px;
  width: 100%;
}

.input-checkbox-container {
  padding: 5px 0;
  margin: 5px 0;
  input[type="checkbox"] {
    margin-right: 5px;
    cursor: pointer;
  }
  label {
    display: inline-block;
    cursor: pointer;
  }
}

label.label-with-radio {
  cursor: pointer;
}

.react-autosuggest__container {
  position: relative;
  .react-autosuggest__suggestions-container {
    background-color: $brand_content_bg;
    &.react-autosuggest__suggestions-container--open {
      position: absolute;
      max-height: 100px;
      overflow-y: scroll;
      border: thin solid #ddd;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      z-index: 9999;
      width: 100%;
      margin-top: 2px;
    }
    ul {
      li {
        padding: 2px 5px;
        &.react-autosuggest__suggestion--highlighted {
          background-color: $brand_heading;
        }
      }
    }
  }
}

.switch-wrapper {
  padding: 5px;
  margin-bottom: 5px;
  span {
    font-size: 12px;
    margin-right: 10px;
  }
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
