.app {
  .pro-sidebar {
    .pro-sidebar-inner {
      .pro-sidebar-layout{
        overflow-y: hidden;
      }
    }
    .pro-sidebar-header {
      border-bottom: $brand_border;
    }
    .pro-sidebar-footer {
      border-top: $brand_border;
    }
    .pro-sidebar-content {
      scrollbar-width: thin;
      overflow-y: auto;
      ul li {
        border-left: 6px solid $sidebar-bg-color;
        .pro-inner-item {
          padding-left: 14px;
        }
      }
    }
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
      svg {
        vertical-align: middle;
      }
    }
  }

  // .collapsed {
  //   .sidebar-btn {
  //     display: inline-block;
  //     border-radius: 50%;
  //     width: 35px;
  //     height: 35px;
  //     line-height: 40px;
  //     padding: 0;
  //   }
  // }

  .active {
    color: white !important;
    border-left: 6px solid $brand_secondary !important;
  }

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }
}
