.queue-print {
  .queue-default-values-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px;
  }
  .value-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    label {
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 600;
    }
    select {
      display: inline-block;
      width: 200px;
      margin-left: 10px;
    }
  }
  .title-content-list {
    border-radius: 5px;
    .title-content {
      padding: 10px !important;
      min-width: 250px;
    }
  }
  .queue-print-info {
    padding: 5px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    min-height: 270px;

    .queue-print-img {
      width: auto;
      min-height: 260px;
      padding: 5px;
      border: 1px solid $sidebar-color;
      border-radius: 5px;
      background-color: $sidebar-color;
      &.markup {
        margin: 0 10px;
      }
      &.graphic {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        display: block;
        width: auto;
        height: auto;
        max-height: 250px;
        margin: 0 auto;
      }
    }
  }
  .active-packingitems{
    .list{
      max-height: 290px;
      overflow-y: auto;

    }
  }
}
