ul.react-paginate {
  margin: 0;
  li{
    display: inline-block;
    padding: 0 5px;
    a {
      cursor: pointer;
    }
    &.selected{
      a {
        color: red;
        font-weight: 400;
      }
    }
    &.disabled {
      a {
        color: grey;
      }
    }
  }
}

.page-count{
  display: flex;
  .show-rows{
    display: flex;
    align-items: center;
    width: 200px;
    margin-left: 20px;
    select{
      width: 60px;
      margin: 0 10px;
    }
  }
}
