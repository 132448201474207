// table
table {
  border-collapse: collapse;
  width: 100%;
  thead tr {
    background-color: #b0bec5;
  }
  thead th {
    padding: 5px;
    // text-align: left;
    border: 1px solid #E7EEEC;
    border-radius: 3px;
    color: $brand_primary;
    font-weight: 500;
    // font-size: 13px;
    div {
      min-height: 0 !important;
    }
  }

  tbody {
    tr td {
      padding: 5px;
      div {
        min-height: 0 !important;
      }
    }
    tr:nth-child(even) {
      background-color: #e0e0e0;
    }
    tr:hover {
      cursor: pointer;
      // border-radius: 5px;
      border-right: thin solid #b0bec5;
      border-bottom: thin solid #b0bec5;
    }
  }
}

table.graphics-table, table.inventoryarea-table{
  tr:hover {
    cursor: none;
    border: none;
  }
}
