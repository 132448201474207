.shipment {
  padding: 10px;
  .shipment-info {
    display: grid;
    grid-template-columns: 400px 400px 1fr;
    gap: 5px;
    margin-bottom: 10px;
    min-height: 400px;
    max-height: 400px;

    .scanned-barcode-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px;
      background-color: $sidebar-color;
      position: relative;
      min-width: 300px;
      max-height: 400px;
      span.scanned-barcode {
        color: $brand_secondary;
        font-size: 6rem;
      }
    }
    .item-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $sidebar-color;
      padding: 5px;
      position: relative;
      max-height: 400px;

      img {
        max-height: 390px;
      }
    }

    span.absolute-postion {
      position: absolute;
      top: 10px;
      left: 10px;
      display: block;
    }
    .checkbox-wrapper {
      position: absolute;
      left: 10px;
      bottom: 10px;
      display: flex;
      background-color: $brand_content_bg;
      padding: 5px;
      border-radius: 5px;
      label {
        margin-right: 10px;
      }
    }

    .shipment-info_detail {
      // background-color: $sidebar-color;
      .title-content-list {
        height: 100%;
        border-radius: 5px;
      }
      span.title {
        font-size: 0.8em;
        text-transform: capitalize;
        display: block !important;
      }
      input.ordered-checkedout {
        // background-color: var(--theme-blue-bg);
        color: $brand_secondary;
        font-size: 1.5em;
        width: 80px;
        text-align: center;
        border: $sidebar-color dotted 1px;
        border-radius: 5px;
      }
    }
  } //end of shipment-info

  .shipment-msg-checkout-wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 5px;
    margin-bottom: 10px;
    min-height: 50px;
    .msg-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.ril__image {
  max-width: 500px !important;
}

#modal_pack .ref-wrapper {
  display: flex;
  justify-content: center;
}

#modal_pack .ref-wrapper > div {
  padding: 5px;
  min-width: 250px;
}

#modal_pack .ref-wrapper span.title {
  display: block;
  color: var(--theme-blue-accent);
}

#modal_pack .ref-wrapper span.content {
  display: block;
  font-size: 10rem;
}

#modal_pack .ref-wrapper .ship-label-btn-wrapper {
  margin-top: 80px;
}

.packing-items-msg-wrapper {
  background-color: var(--theme-blue-bg);
}

img.ril__imagePrev,
img.ril__imageNext {
  display: none;
}
