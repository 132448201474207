.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .card {
    width: 400px;
    padding: 20px;
    .input-field {
      input {
        width: 100%;
      }
    }
  }
}
