// @leafygreen custom
.lg-banner {
  margin-bottom: 10px;
}

.lg-modal {
  .modal-header {
    color: #fff;
    border-radius: 6px 6px 0 0;
    padding: 10px 20px;
    background-image: url(https://res.cloudinary.com/net-ninja/image/upload/v1616238571/Net%20Ninja%20Pro/pro-images/pro-banner_rwboej.png);
  }
  .modal-content {
    padding: 0;
  }
  form {
    padding: 10px 20px;
  }
}

.lg-text-input {
  margin-bottom: 20px;
}

.lg-toggle-wrapper {
  display: flex;
  align-items: center;
}

.lg-toggle-label {
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #3d4f58;
}

.lg-form-footer {
  margin-top: 10px;
}

.lg-sidenav {
  nav {
    background-color: $sidebar-bg-color;
    border: none;
    z-index: 100;
    li button {
      color: $sidebar-color !important;
    }

    .lg-sidenav-group {
      button[data-leafygreen-ui="side-nav-group-button"] {
        border-left: black 3px solid !important;
        border-bottom: 1px solid black !important;
        svg,
        span {
          color: #116149;
        }
      }
    }
  }
  button:last-child {
    z-index: 100;
  }
}

.lg-tooltip-container {
  padding: 5px 10px;
}
